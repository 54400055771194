export default {
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  tradingCompetition: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
   predictions: {
    56: '0xD80440a7dA7199a8164ad2c75f3594465Dd1522D',
    97: '0xaE585B2407b7a61Fa35C6E88A6a402CE79234525',
  },
  chainlinkOracle: {
    56: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    97: '0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526',
  }
}
